import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import authService from '@/services/authService';
import jwt_decode from 'jwt-decode';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/:catchAll(.*)',
      name: '404',
      component: () => import('../views/404View.vue'),
    },
    {
      path: '/sentry',
      name: 'sentry',
      component: () => import('../views/SentryView.vue'),
    },
    {
      path: '/',
      name: 'home',
      component: () => import('../views/HomeView.vue'),
    },
    {
      path: '/documento/assinar/novo',
      name: 'assinar-novo-documento',
      component: () => import('../views/AssinarNovoDocumentoView.vue'),
    },
    {
      path: '/documento/assinar/:id',
      name: 'assinar-documento',
      component: () => import('../views/AssinarDocumentoView.vue'),
    },
    {
      path: '/verificar/:id?',
      name: 'verificar-assinaturas',
      component: () => import('../views/VerificarAssinatura.vue'),
    },
    {
      path: '/documentos/painel',
      name: 'painel-de-documentos',
      component: () => import('../views/PainelDeDocumentos.vue'),
    },
    {
      path: '/documento/historico/:id_registro',
      name: 'documentos-historicos',
      component: () => import('../views/HistoricoRegistro.vue'),
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const publicPages = ['404', 'verificar-assinaturas'];
  const authRequired = !publicPages.includes(to.name);
  const { logout } = to.query;
  const ambiente = import.meta.env.VITE_ENVIRONMENT;
  const auth = useAuthStore();

  if (logout) {
    auth.logoutCallback();
    auth.login();
    return false;
  }

  if (authRequired) {
    if (!auth.token) {
      auth.login();
      return false;
    }

    const jwtDecodificado = jwt_decode(auth.token);

    if (ambiente !== 'development' && jwtDecodificado?.acr.includes('baixo')) {
      auth.login();
      return false;
    }

    const authenticated = await auth.verifyLogin();

    if (!authenticated) {
      auth.logoutCallback();
      auth.login();
      return false;
    }
  }

  if (auth.token) auth.usuario = await authService.retornaInformacoesUsuario();

  auth.carregando = false;
  next();
});

export default router;
