const Helper = {
  opcoesClaveCPF: () => {
    return {
      blocks: [3, 3, 3, 2],
      delimiters: ['.', '.', '-'],
      numericOnly: true,
      copyDelimiter: true,
      copyDelimiters: true,
    };
  },

  opcoesClaveData: () => {
    return {
      date: true,
      delimiter: '/',
      datePattern: ['d', 'm', 'Y'],
    };
  },

  maskForm: (data, mask) => {
    const dataSplit = data.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '').split('');
    dataSplit.forEach((character) => {
      mask = mask.replace('#', character);
    });
    return mask;
  },

  formatarCelular(pais, numero) {
    if (!pais) {
      if (!numero) return ' - ';
      const numeroLimpo = numero.replace(/\D/g, '');
      const ddd = numeroLimpo.slice(0, 2);
      const ddi = numeroLimpo.slice(2, 4);
      const parte1 = numeroLimpo.slice(4, 9);
      const parte2 = numeroLimpo.slice(9, 13);

      if (ddd === '55') {
        return `+${ddd} (${ddi}) ${parte1}-${parte2}`;
      } else {
        return `+${ddd}${ddi}${parte1}${parte2}`;
      }
    }

    if (numero) {
      let numeroLimpo = numero?.replace(/\D/g, '');
      let mascara = this.retornaDDDPaises()[pais];
      let indexUltimoNumero;
      let numeroFormatado = mascara;

      if (numeroLimpo?.slice(0, mascara.replace(/\D/g, '').length) === mascara.replace(/\D/g, '')) {
        numeroLimpo = numeroLimpo.slice(mascara.replace(/\D/g, '').length);
      }

      if (numeroLimpo) {
        numeroLimpo.split('').map((caractere) => {
          numeroFormatado = numeroFormatado.replace('#', caractere);
        });
        indexUltimoNumero = numeroFormatado
          .replaceAll('#', '')
          .split('')
          .findLastIndex((caracter) => {
            return !isNaN(Number(caracter));
          });
        return numeroFormatado.slice(0, indexUltimoNumero + 1);
      } else {
        return mascara.slice(
          0,
          mascara
            .replace('#', '')
            .split('')
            .findLastIndex((caracter) => {
              return !isNaN(Number(caracter));
            }) + 1
        );
      }
    }
  },

  apagarMascara(pais, numero) {
    const mascara = this.retornaDDDPaises()[pais];
    const comprimentoMascara = mascara.substring(0, mascara.indexOf('#')).length;
    if (numero && numero.length <= comprimentoMascara) return '';
    else return this.formatarCelular(pais, numero);
  },

  validarCelular(pais, numero) {
    if (!pais) return false;
    if (!numero?.length) return false;
    if (this.retornaDDDPaises()[pais].length === numero.length) {
      const arrayNumero = numero
        .replace(/\D/g, '')
        .replace(this.retornaDDDPaises()[pais].replace(/\D/g, ''), '')
        .split('');
      return !arrayNumero.every((num) => num === arrayNumero[0]);
    } else {
      return false;
    }
  },

  formatarCPF(cpf) {
    if (!cpf) return;
    const cpfLimpo = cpf.replace(/\D/g, '');
    if (cpfLimpo.length === 11) {
      return (
        cpfLimpo.substring(0, 3) +
        '.' +
        cpfLimpo.substring(3, 6) +
        '.' +
        cpfLimpo.substring(6, 9) +
        '-' +
        cpfLimpo.substring(9)
      );
    }
  },

  validarEmail(email) {
    const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regexEmail.test(email);
  },

  retornaDDDPaises() {
    return {
      AC: '+247-####',
      AD: '+376-###-###',
      AE: '+971-#-###-####',
      AF: '+93-##-###-####',
      AG: '+1(268)###-####',
      AI: '+1(264)###-####',
      AL: '+355(###)###-###',
      AM: '+374-##-###-###',
      AN: '+599-####-####',
      AO: '+244(###)###-###',
      AQ: '+672-###-###',
      AR: '+54(###)###-####',
      AS: '+1(684)###-####',
      AT: '+43(###)###-####',
      AU: '+61-#-####-####',
      AW: '+297-###-####',
      AZ: '+994-##-###-##-##',
      BA: '+387-##-#####',
      BB: '+1(246)###-####',
      BD: '+880-##-###-###',
      BE: '+32(###)###-###',
      BF: '+226-##-##-####',
      BG: '+359(###)###-###',
      BH: '+973-####-####',
      BI: '+257-##-##-####',
      BJ: '+229-##-##-####',
      BM: '+1(441)###-####',
      BN: '+673-###-####',
      BO: '+591-#-###-####',
      BR: '+55(##)#####-####',
      BS: '+1(242)###-####',
      BT: '+975-##-###-###',
      BW: '+267-##-###-###',
      BY: '+375(##)###-##-##',
      BZ: '+501-###-####',
      CA: '+1(###)###-####',
      CD: '+243(###)###-###',
      CF: '+236-##-##-####',
      CG: '+242-##-###-####',
      CH: '+41-##-###-####',
      CI: '+225-##-###-###',
      CK: '+682-##-###',
      CL: '+56-#-####-####',
      CM: '+237-####-####',
      CN: '+86-##-#####-#####',
      CO: '+57(###)###-####',
      CR: '+506-####-####',
      CU: '+53-#-###-####',
      CV: '+238(###)##-##',
      CW: '+599-###-####',
      CY: '+357-##-###-###',
      CZ: '+420(###)###-###',
      DE: '+49(###)####-####',
      DJ: '+253-##-##-##-##',
      DK: '+45-##-##-##-##',
      DM: '+1(767)###-####',
      DO: '+1(###)###-####',
      DZ: '+213-##-###-####',
      EC: '+593-##-###-####',
      EG: '+20(###)###-####',
      ER: '+291-#-###-###',
      ES: '+34(###)###-###',
      ET: '+251-##-###-####',
      FI: '+358(###)###-##-##',
      FJ: '+679-##-#####',
      FK: '+500-#####',
      FM: '+691-###-####',
      FO: '+298-###-###',
      FR: '+33(###)###-###',
      GA: '+241-#-##-##-##',
      GD: '+1(473)###-####',
      GE: '+995(###)###-###',
      GF: '+594-#####-####',
      GH: '+233(###)###-###',
      GI: '+350-###-#####',
      GL: '+299-##-##-##',
      GM: '+220(###)##-##',
      GN: '+224-##-###-###',
      GQ: '+240-##-###-####',
      GR: '+30(###)###-####',
      GT: '+502-#-###-####',
      GU: '+1(671)###-####',
      GW: '+245-#-######',
      GY: '+592-###-####',
      HK: '+852-####-####',
      HN: '+504-####-####',
      HR: '+385-##-###-###',
      HT: '+509-##-##-####',
      HU: '+36(###)###-###',
      ID: '+62(###)###-##-###',
      IE: '+353(###)###-###',
      IL: '+972-##-###-####',
      IN: '+91(####)###-###',
      IO: '+246-###-####',
      IQ: '+964(###)###-####',
      IR: '+98(###)###-####',
      IS: '+354-###-####',
      IT: '+39(###)####-###',
      JM: '+1(876)###-####',
      JO: '+962-#-####-####',
      JP: '+81-##-####-####',
      KE: '+254-###-######',
      KG: '+996(###)###-###',
      KH: '+855-##-###-###',
      KI: '+686-##-###',
      KM: '+269-##-#####',
      KN: '+1(869)###-####',
      KP: '+850-####-#############',
      KR: '+82-##-###-####',
      KW: '+965-####-####',
      KY: '+1(345)###-####',
      KZ: '+7(###)###-##-##',
      LA: '+856(####)###-###',
      LB: '+961-##-###-###',
      LC: '+1(758)###-####',
      LI: '+423(###)###-####',
      LK: '+94-##-###-####',
      LR: '+231-##-###-###',
      LS: '+266-#-###-####',
      LT: '+370(###)##-###',
      LU: '+352(###)###-###',
      LV: '+371-##-###-###',
      LY: '+218-##-###-####',
      MA: '+212-##-####-###',
      MC: '+377(###)###-###',
      MD: '+373-####-####',
      ME: '+382-##-###-###',
      MG: '+261-##-##-#####',
      MH: '+692-###-####',
      MK: '+389-##-###-###',
      ML: '+223-##-##-####',
      MM: '+95-##-###-###',
      MN: '+976-##-##-####',
      MO: '+853-####-####',
      MP: '+1(670)###-####',
      MQ: '+596(###)##-##-##',
      MR: '+222-##-##-####',
      MS: '+1(664)###-####',
      MT: '+356-####-####',
      MU: '+230-###-####',
      MV: '+960-###-####',
      MW: '+265-#-####-####',
      MX: '+52(###)###-####',
      MY: '+60(###)###-###',
      MZ: '+258-##-###-###',
      NA: '+264-##-###-####',
      NC: '+687-##-####',
      NE: '+227-##-##-####',
      NF: '+672-###-###',
      NG: '+234(###)###-####',
      NI: '+505-####-####',
      NL: '+31-##-###-####',
      NO: '+47(###)##-###',
      NP: '+977-##-###-###',
      NR: '+674-###-####',
      NU: '+683-####',
      NZ: '+64(###)###-####',
      OM: '+968-##-###-###',
      PA: '+507-###-####',
      PE: '+51(###)###-###',
      PF: '+689-##-##-##',
      PG: '+675(###)##-###',
      PH: '+63(###)###-####',
      PK: '+92(###)###-####',
      PL: '+48(###)###-###',
      PS: '+970-##-###-####',
      PT: '+351-##-###-####',
      PW: '+680-###-####',
      PY: '+595(###)###-###',
      QA: '+974-####-####',
      RE: '+262-#####-####',
      RO: '+40-##-###-####',
      RS: '+381-##-###-####',
      RU: '+7(###)###-##-##',
      RW: '+250(###)###-###',
      SA: '+966-#-####-####',
      SB: '+677-###-####',
      SC: '+248-#-###-###',
      SD: '+249-##-###-####',
      SE: '+46-##-###-####',
      SG: '+65-####-####',
      SH: '+290-####',
      SI: '+386-##-###-###',
      SK: '+421(###)###-###',
      SL: '+232-##-######',
      SM: '+378-####-######',
      SN: '+221-##-###-####',
      SO: '+252-##-###-###',
      SR: '+597-###-####',
      SS: '+211-##-###-####',
      ST: '+239-##-#####',
      SV: '+503-##-##-####',
      SX: '+1(721)###-####',
      SY: '+963-##-####-###',
      SZ: '+268-##-##-####',
      TC: '+1(649)###-####',
      TD: '+235-##-##-##-##',
      TG: '+228-##-###-###',
      TH: '+66-##-###-####',
      TJ: '+992-##-###-####',
      TK: '+690-####',
      TL: '+670-###-#####',
      TM: '+993-#-###-####',
      TN: '+216-##-###-###',
      TO: '+676-#####',
      TR: '+90(###)###-####',
      TT: '+1(868)###-####',
      TV: '+688-######',
      TW: '+886-#-####-####',
      TZ: '+255-##-###-####',
      UA: '+380(##)###-##-##',
      UG: '+256(###)###-###',
      UK: '+44-##-####-####',
      US: '+1(###)###-####',
      UY: '+598-#-###-##-##',
      UZ: '+998-##-###-####',
      VA: '+39-6-698-#####',
      VC: '+1(784)###-####',
      VE: '+58(###)###-####',
      VG: '+1(284)###-####',
      VI: '+1(340)###-####',
      VN: '+84(###)####-###',
      VU: '+678-##-#####',
      WF: '+681-##-####',
      WS: '+685-##-####',
      YE: '+967-###-###-###',
      ZA: '+27-##-###-####',
      ZM: '+260-##-###-####',
      ZW: '+263-#-######',
    };
  },

  validarCPF(cpf) {
    cpf = cpf.replace(/\D/g, '');
    if (cpf.length !== 11) {
      return false;
    }
    if (/^(\d)\1+$/.test(cpf)) {
      return false;
    }
    let sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let remainder = 11 - (sum % 11);
    let digit1 = remainder === 10 || remainder === 11 ? 0 : remainder;
    sum = 0;
    for (let i = 0; i < 10; i++) {
      sum += parseInt(cpf.charAt(i)) * (11 - i);
    }
    remainder = 11 - (sum % 11);
    let digit2 = remainder === 10 || remainder === 11 ? 0 : remainder;
    return parseInt(cpf.charAt(9)) === digit1 && parseInt(cpf.charAt(10)) === digit2;
  },

  verificaDispositivoCelularTablet() {
    return (window.mobileAndTabletCheck = function () {
      let check = false;

      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);

      return check;
    })();
  },

  dataAmigavel(data) {
    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0'); // Os meses começam do zero, então somamos 1
    const ano = data.getFullYear();
    const horas = String(data.getHours()).padStart(2, '0');
    const minutos = String(data.getMinutes()).padStart(2, '0');
    const segundos = String(data.getSeconds()).padStart(2, '0');

    return `${dia}/${mes}/${ano} ${horas}:${minutos}:${segundos}`;
  },

  posicionaEstampaDefault: async (arquivo, assinador) => {
    const pdfWorker = await import('pdfjs-dist/build/pdf.worker');
    const pdfJS = await import('pdfjs-dist/build/pdf.min');

    pdfJS.GlobalWorkerOptions.workerSrc = pdfWorker;

    const pdf = await pdfJS.getDocument(URL.createObjectURL(arquivo)).promise;
    const paginaPDF = await pdf.getPage(parseInt(1));
    const viewportPDF = paginaPDF.getViewport({ scale: 1 });

    return {
      horizontal: viewportPDF.width / 2 - assinador.assinatura.coordenadas.largura / 2,
      vertical: viewportPDF.height - 20,
    };
  },

  retornarDadosPDF: async (arquivo) => {
    const pdfWorker = await import('pdfjs-dist/build/pdf.worker');
    const pdfJS = await import('pdfjs-dist/build/pdf.min');
    pdfJS.GlobalWorkerOptions.workerSrc = pdfWorker;

    return await pdfJS.getDocument(URL.createObjectURL(arquivo)).promise;
  },

  arquivoValido: (arquivo) => arquivo instanceof File,

  orquestradorNoInicioDoArray(array, cpfOrquestrador) {
    if (!Array.isArray(array) || array.length === 0 || !cpfOrquestrador) {
      return array;
    }

    const index = array.findIndex(item => item.cpf === cpfOrquestrador);
    if (index !== -1) {
      const [item] = array.splice(index, 1);
      array.unshift(item);
    }

    return array;
  },
};

export default Helper;
