import { useAuthStore } from '@/stores/auth';

export const fetchWrapper = {
  get: request('GET'),
  post: request('POST'),
  put: request('PUT'),
  delete: request('DELETE'),
};

function request(method) {
  return async (url, body, withFiles, config = null) => {
    const requestOptions = {
      ...config,
      method,
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      },
    };

    if (body) {
      if (!withFiles) requestOptions.headers['Content-Type'] = 'application/json';
      requestOptions.body = !withFiles ? JSON.stringify(body) : body;
    }

    if (withFiles && config) return fetch(url, requestOptions).then(handleResponseFile);

    return fetch(url, requestOptions).then(handleResponse);
  };
}

// helper functions

function handleResponse(response) {
  const authStore = useAuthStore();

  return response.text().then(async (text) => {
    let data;

    try {
      data = text && JSON.parse(text.toString());
    } catch (e) {
      data = text;
    }

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        authStore.logoutCallback();
      }

      let messages = null;
      if (data?.messages) messages = Object.values(data.messages).join('\n');

      let error = data?.message || messages || response.statusText;

      if (error === 'Unauthorized') {
        error = 'Acesso Negado';
      }

      return Promise.reject(error);
    }

    return data;
  });
}

function handleResponseFile(response) {
  const authStore = useAuthStore();

  if (!response.ok) {
    if ([401, 403].includes(response.status)) {
      authStore.logoutCallback();
    }

    return Promise.reject('Houve um problema no servidor, tente novamente mais tarde.');
  }

  return response.arrayBuffer();
}
