import * as Sentry from '@sentry/vue';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import 'bootstrap';

import App from './App.vue';
import router from './router';

import '@/assets/css/main.scss';

const app = createApp(App);

if (import.meta.env?.VITE_SENTRY_ENABLE && import.meta.env.VITE_SENTRY_ENABLE === 'true') {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DNS,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [import.meta.env.VITE_URL],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    tracesSampleRate: 0.0,
  });
}

app.use(Toast).use(createPinia()).use(router).mount('#app');
