<script setup>
import { onMounted, ref } from 'vue';

const menuLateralRef = ref();

onMounted(() => {
  window.addEventListener('click', function (e) {
    if (
      menuLateralRef.value &&
      !menuLateralRef.value.contains(e.target) &&
      menuLateralRef.value.classList.contains('aberto')
    ) {
      menuLateralRef.value.classList.remove('aberto');
      menuLateralRef.value.classList.add('fechado');
    }
  });
});

const alterarEstadoMenuLateral = () => {
  if (menuLateralRef.value.classList.contains('fechado')) {
    menuLateralRef.value.classList.remove('fechado');
    menuLateralRef.value.classList.add('aberto');
    return;
  }

  menuLateralRef.value.classList.remove('aberto');
  menuLateralRef.value.classList.add('fechado');
};
</script>
<template>
  <div id="menuLateral" ref="menuLateralRef" class="fechado">
    <nav>
      <ul>
        <li class="container-hamburger" @click="alterarEstadoMenuLateral">
          <div class="hamburguer">
            <div></div>
            <div></div>
            <div></div>
          </div>
          <a>Menu</a>
        </li>

        <li>
          <div>
            <span class="icone-home"></span>
          </div>
          <a @click="$router.push('/')">Página Inicial</a>
        </li>
      </ul>
    </nav>
  </div>
</template>
<style lang="scss" scoped>
@import '@/assets/css/arpen-bootstrap-variables';

#menuLateral.fechado {
  width: 75px;

  nav {
    ul {
      li.container-hamburger {
        align-items: normal;

        a {
          margin-left: 0;
        }
      }

      li {
        a {
          display: none;
        }
      }
    }
  }
}

#menuLateral.aberto {
  width: 270px;

  nav {
    ul {
      li.container-hamburger {
        &:hover {
          background-color: #fff;

          a {
            color: $azul;
          }
        }
      }

      li {
        flex-direction: row;

        a {
          opacity: 1;
          animation: mostrarMenu 1s;
        }

        &:hover {
          background-color: $verde;

          a {
            color: $branco;

            &:hover {
              opacity: 1;
            }
          }

          .icone-home {
            background-image: url('@/assets/images/menu-lateral/home-branco.svg');
          }
        }
      }
    }
  }
}

#menuLateral {
  box-shadow: 0 3px 6px #00000029;
  padding: 20px 0;
  background-color: #fff;
  transition: width 100ms;
  height: 100%;
  position: absolute;
  z-index: 9997;

  nav {
    ul {
      li.container-hamburger {
        cursor: pointer;

        .hamburguer {
          width: 45px;
          display: flex;
          align-items: center;
          flex-direction: column;

          ~ a {
            opacity: 1;
            display: block;
            margin-top: 2px;
          }

          div {
            width: inherit;
            border-radius: 10px;
            border: 2px solid #3abbc6;
            margin-bottom: 8px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      li {
        padding: 15px;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 10px;
        transition: background-color 300ms;

        div {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 45px;
        }

        a {
          text-decoration: none;
          margin-left: 18px;
          color: $azul;
          cursor: pointer;
          opacity: 0;
        }

        .icone-home {
          background-repeat: no-repeat;
          background-image: url('@/assets/images/menu-lateral/home.svg');
          width: 30px;
          height: 30px;
          display: block;
        }
      }
    }
  }

  @media (max-width: 1460px) {
    position: relative;
    height: auto;
    display: flex;
    flex-direction: row;
    width: 100% !important;
    padding: 5px 0;

    nav {
      width: inherit;

      ul {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        li.container-hamburger {
          display: none;
        }

        li {
          margin: 0;

          div {
            display: none;
          }
          a {
            opacity: 1;
            display: block !important;
          }
        }
      }
    }
  }
}

@keyframes mostrarMenu {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
